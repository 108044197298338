import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Carousel, CarouselContent, CarouselItem, type CarouselApi } from '@/components/ui/carousel';
import Image from 'next/image';
import { ScrollArea } from '@components/ui/scroll-area';

interface PhotoGuidelinesCarouselProps {
  defaultOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const guidelines = [
  {
    title: 'Wait! Make sure to avoid these common mistakes.',
    description:
      'Want flawless portraits? Make sure to follow these simple tips and avoid common mistakes. These guidelines are key to getting the best results!',
    image: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/d0561ca8-a7f0-49cf-973a-310d61f5ab00/1024'
    // image: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/107fa7fe-8156-4c3d-4529-ed792ef2d000/public'
    // image: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/3cf75f88-00e3-431f-2296-6c12042f0000/1024'
  },
  {
    title: 'No Winking, Tongue Out, or Other Silly Faces',
    description:
      'We love your personality, but the AI? Not so much. Avoid photos where you\'re winking, sticking your tongue out, or making that classic "Jim Halpert" face. Keep it natural and professional for the best results!',
    image: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/ce8593ab-5990-41e4-2c48-330b84f0e400/1024'
  },
  {
    title: "Don't use photos from the same spot",
    description:
      'Variety is key! Use different backgrounds, angles, and lighting to give the AI a well-rounded view of your appearance.',
    image: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/79409556-9077-4bf4-3793-723011baca00/1024'
  },
  {
    title: 'No Photos Looking Away',
    description:
      'Make sure your face is clearly visible and facing the camera. The AI needs to see your features to generate great portraits.',
    image: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/9319a251-61e6-4a6b-bb68-276573fa0800/1024'
  },
  {
    title: 'Avoid poor lighting',
    description:
      'Good lighting is everything! Dark or shadowy images will result in poor-quality portraits. Make sure your face is well-lit and evenly exposed.',
    image: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/94890c81-487c-470a-a791-2734d4236a00/1024'
  }
  // {
  //   title: 'No Blurry or Pixelated Images',
  //   description:
  //     "Blurry or pixelated photos won't give you the best results. Make sure your images are sharp and clear so the AI can work its magic.",
  //   image: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/94890c81-487c-470a-a791-2734d4236a00/1024'
  // }
];

export default function PhotoGuidelinesCarousel({ defaultOpen = false, onOpenChange }: PhotoGuidelinesCarouselProps) {
  const [open, setOpen] = useState(defaultOpen);
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!api) return;
    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap());
    api.on('select', () => setCurrent(api.selectedScrollSnap()));
  }, [api]);

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  const handleNext = () => {
    if (current === count - 1) setOpen(false);
    else api?.scrollNext();
  };

  const handlePrevious = () => api?.scrollPrev();

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
    onOpenChange?.(newOpen);
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange} defaultOpen={true}>
      <DialogTrigger asChild>
        <Button variant="outline">Photo Guidelines</Button>
      </DialogTrigger>
      <DialogContent className="!rounded-none !max-w-[100vw] !w-screen !h-screen p-0">
        <div className="flex h-full">
          {/* COL 1 */}
          <div className="w-full md:w-1/2 flex flex-col p-2">
            <ScrollArea className="h-full w-full">
              <div className="flex flex-col gap-6 h-full p-4 lg:px-12 xl:px-24 relative">
                <DialogHeader className="mb-4">
                  <DialogTitle>
                    <h1 className="text-center text-xl">Tips & Guidelines</h1>
                  </DialogTitle>
                </DialogHeader>
                <div className="my-10">
                  <Carousel className="w-full flex-grow rounded-lg overflow-hidden" setApi={setApi}>
                    <CarouselContent className="flex-grow flex-shrink">
                      {guidelines.map((guideline, index) => (
                        <CarouselItem key={index} className="flex flex-col h-full">
                          <div className="flex-grow flex-shrink relative mb-4 h-full justify-center items-center flex">
                            <Image
                              src={guideline.image}
                              alt={guideline.title}
                              width={3}
                              height={2}
                              objectFit="cover"
                              className="rounded-lg h-full max-h-[40vh] w-auto aspect-[3/2]"
                            />
                          </div>
                        </CarouselItem>
                      ))}
                    </CarouselContent>
                    <div className="mt-4 mb-2 text-center">
                      {Array.from({ length: count }).map((_, index) => (
                        <span
                          key={index}
                          className={`inline-block h-2 w-8 mx-1 rounded-full ${
                            index === current ? 'bg-primary' : 'bg-muted'
                          }`}
                        />
                      ))}
                    </div>
                  </Carousel>
                </div>
                <div className="mt-auto max-w-[512px]">
                  <h3 className="lg:text-3xl text-xl font-semibold mb-2">{guidelines[current]?.title}</h3>
                  <p className="lg:text-lg text-base text-muted-foreground">{guidelines[current]?.description}</p>
                </div>
                <div className="flex flex-col gap-2 w-full sticky bottom-0 bg-background/80">
                  <div className="flex justify-end gap-2 mt-6">
                    <Button
                      size="lg"
                      onClick={handlePrevious}
                      className={current === 0 ? 'hidden' : ''}
                      variant="outline"
                    >
                      Back
                    </Button>
                    <Button variant="accent" size="lg" onClick={handleNext} className={current === 0 ? '!w-full' : ''}>
                      {current === 0 ? 'Get the best results' : current === count - 1 ? 'Upload my photos' : 'Next'}
                    </Button>
                  </div>
                  {current === 0 ? (
                    <Button variant="ghost" size="lg" onClick={() => setOpen(false)} className="mt-0">
                      No thanks
                    </Button>
                  ) : null}
                </div>
              </div>
            </ScrollArea>
          </div>
          {/* COL 2 */}
          <div className="hidden md:flex flex-col w-1/2 bg-muted dark:bg-neutral-900 justify-center items-center gap-4">
            <Image
              src="/svgs/glyph.svg"
              alt="glyph"
              width={128}
              height={128}
              className="invert dark:invert-0 opacity-10"
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
