import React from 'react';
import Link from 'next/link';
import { Button } from '@components/ui/button';
import Image from 'next/image';

export default function Packs() {
  return (
    <div className="relative bg-purple-500/0 justify-center flex flex-col items-center">
      <div className="">
        <div className="mx-auto max-w-2xl text-center items-center justify-center flex flex-col mb-10">
          <h2 className="text-4xl font-bold tracking-tight text-studio-900 sm:text-6xl">Pick a photo pack</h2>
          <p className="mt-6 text-lg leading-8 text-studio-600 max-w-lg w-full mb-10">
            Get photos in a variety of styles and themes. Choose from our selection of packs to get started.
          </p>
          <div className="flex items-center justify-center gap-x-6">
            <Button className="" variant={'accent'} size={'xl'} asChild>
              <Link href="/packs/browse">Browse packs</Link>
            </Button>
          </div>
        </div>
      </div>
      <div className="px-6 lg:px-8 md:-mx-32 lg:-mx-48 xl:-mx-64 overflow-x-auto w-full">
        <div className="grid grid-cols-3 sm:grid-cols-5 gap-1 md:gap-4 bg-red-500/0 pb-16">
          {/* First pack */}
          <div className="pt-0 hidden sm:block pb-8">
            {/* Link=/packs/browse/cm0emih000001siy5uxsq7a65 */}
            <Link href="/packs/browse/cm0emih000001siy5uxsq7a65">
              {' '}
              <Image
                alt={'Founder Pro'}
                src={'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/1ff7f0b7-8f58-45db-40ec-3378ded73800/public'}
                width={1000}
                height={1000}
                className="col-span-1 rounded-3xl shadow-[rgba(0,_0,_0,_0.3)_0px_16px_16px_-7px]"
              />
            </Link>
          </div>
          {/* Second pack */}
          <div className="relative pt-[30%]">
            {/* Link=packs/browse/cm05gknrr0001vw563rt23y7a */}
            <Link href="/packs/browse/cm05gknrr0001vw563rt23y7a">
              <Image
                alt={'Dating Profile'}
                src={'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/5d13f864-567c-463b-5716-fd4a8082ac00/public'}
                width={1000}
                height={1000}
                className="col-span-1 rounded-3xl shadow-[rgba(0,_0,_0,_0.3)_0px_16px_16px_-7px]"
              />
            </Link>
          </div>
          {/* Middle pack */}
          <div className="pt-[15%]">
            {/* Link=packs/browse/clzzqill30001mavqre7dn04h */}
            <Link href="/packs/browse/clzzqill30001mavqre7dn04h">
              <Image
                alt={'Wired Portrait'}
                src={'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/94d99780-5f1f-4586-9fd7-ec48e1435400/public'}
                width={1000}
                height={1000}
                className="col-span-1 rounded-3xl shadow-[rgba(0,_0,_0,_0.3)_0px_16px_16px_-7px]"
              />
            </Link>
          </div>
          {/* Fourth pack */}
          <div className="pt-[30%]">
            {/* Link=https://www.picstudio.ai/packs/browse/cm0bc1x3i0001m7utu8kwr0lz */}
            <Link href="/packs/browse/cm0bc1x3i0001m7utu8kwr0lz">
              <Image
                alt={'Casual Colors'}
                src={'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/47c14196-9e65-497e-0fbc-1ec236898800/public'}
                width={1000}
                height={1000}
                className="col-span-1 rounded-3xl shadow-[rgba(0,_0,_0,_0.3)_0px_16px_16px_-7px]"
              />
            </Link>
          </div>
          {/* Last pack */}
          <div className="pt-0 hidden sm:block">
            {/* Link="/packs/browse/1b58ebef-5e10-4607-9b2b-40698f711d48" */}
            <Link href="/packs/browse/1b58ebef-5e10-4607-9b2b-40698f711d48">
              <Image
                alt={'Office Pro'}
                src={'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/70a3e2ae-7276-4df9-6a2f-dfdbcde68400/public'}
                width={1000}
                height={1000}
                className="col-span-1 rounded-3xl shadow-[rgba(0,_0,_0,_0.3)_0px_16px_16px_-7px]"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
